import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

class ProjectPostTemplate extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const post = this.props.data.markdownRemark;
    const { previous, next } = this.props.pageContext;

    return (
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.spoiler}
          slug={post.fields.slug}
        />
        <div className="article">
          <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
            <div className="flex-item">
              <h1 className="section-title">{post.frontmatter.title}</h1>
              <h4 className="">{post.frontmatter.spoiler}</h4>
            </div>
            <div className="flex-item">
              {post.frontmatter.tags &&
              post.frontmatter.tags.map(tag => (
                <span key={tag} className="subtitle-tag">
                  #{tag}
                </span>
              ))}
            </div>
          </div>
          <article dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
        <div className="post-footer">
          <p className="subtitle text-center">
            Updated: {post.frontmatter.date}
          </p>
          <ul
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              listStyle: 'none',
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link className="nav-links" to={previous.fields.slug} rel="prev">
                  <FontAwesomeIcon icon={faArrowLeft} /> {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && !next.frontmatter.draft && (
                <Link className="nav-links" to={next.fields.slug} rel="next">
                   {next.frontmatter.title} <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              )}
            </li>
          </ul>
        </div>
      </Layout>
    );
  }
}

export default ProjectPostTemplate;

export const pageQuery = graphql`
  query ProjectPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        type
        date(formatString: "MMMM DD, YYYY")
        spoiler
        path
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
